<div class="custom-nav mb-2">
    <button class="mr-2" mat-icon-button (click)="back()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <span class="bold-small">Returns & Refund Policy</span>
</div>
<div *ngIf="shopModel.id!=null">
    <div *ngIf="shopModel.id != '14'" class="container">
        <span class="bold-small">Buyer policies:</span>
        <p class="regular-blue">
            As a buyer, you need to accept and follow our rules and policies before you can purchase an item. This helps
            ensure a smoother, more enjoyable transaction for both buyers and sellers.
        </p>
    
        <span class="bold-small">Cancellation Policy for Buyers:</span>
        <p class="regular-blue">
            Once an order is successfully placed but not confirmed, it can be cancelled by either the buyer or the seller.
            Once an order is successfully placed and confirmed, it can be cancelled by the buyer only.
            An order cannot be cancelled once it is dispatched or while in transit or is delivered.
            For customised Jewellery, orders once confirmed cannot be cancelled.
        </p>
    
    
        <span class="bold-small">Return Policy for Buyers:</span>
        <p class="regular-blue">
            Return requests will be accepted only when an order is delivered successfully.
            Returns will be accepted up to 10 days post successful delivery of any order i.e. delivery + 10 days.
            Items weighing below 4 grams of Gold, 50 gms of silver and customised Jewellery are not eligible for returns.
            Buyer can return the order after contacting our store.
            A return will only be accepted by the seller in case of unused and undamaged product.
            Once an order is returned to the seller, the refund will be processed within 7 working days.
    
        </p>
    
    
        <span class="bold-small">Shipping
            :</span>
        <p class="regular-blue">
            Customers will be responsible for paying for your own shipping costs for returning your item. Shipping costs are
            non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.
        </p>
    
        <span class="bold-small">Refund Policy for Buyers: </span>
        <p class="regular-blue">
            In order to be eligible for a refund, you have to return the product within 10 calendar days of your purchase.
            The product must be in the same condition that you receive it and undamaged in any way.
            After we receive your item, our team of professionals will inspect it and process your refund. The money will be
            refunded to the original payment method you’ve used during the purchase. For credit card payments it may take 5
            to 10 business days for a refund to show up on your credit card statement.
            If the product is damaged in any way, or you have initiated the return after 10 calendar days have passed, you
            will not be eligible for a refund.
        </p>
    
    </div>
    
    <!-- custom content only for sonar sansar id 14 -->
    <div *ngIf="shopModel.id == '14'" class="container">
        <section>
            <h2 class="semibold-20">CANCELLATION & REFUND POLICY</h2>
    
            <p class="regular-blue"><strong>Pre-Shipping Cancellation:</strong> If a customer is willing to cancel his/her
                order before the item has been shipped, then the customer has to mail us at <a>cc@sonarsansar.com</a> stating the reason for cancellation, and the
                amount shall be refunded back to the customer within 10 working days.</p>
    
            <p class="regular-blue"><strong>Post-Shipping Cancellation:</strong> If a customer is willing to cancel his/her
                order after the item has been shipped, then cancellation will not be possible, and the customer will have to
                follow the return policy.</p>
    
            <h2 class="semibold-20">Easy Returns:</h2>
            <p class="regular-blue">We strive to provide you with an experience that makes you feel confident in your
                purchase and use of our products & services. We hope your selection brings you many years of enjoyment.</p>
    
            <h2 class="semibold-20">How to Initiate the Refund Process?</h2>
            <div class="regular-blue">
                <ul>
                    <li>Customers can return the product if the wrong size was ordered or if the product was damaged upon delivery. This must be done within 7 days from the date of delivery.</li>
                    <li>Customers must contact Sonar Sansar customer support at 844-77-46337 or write to cc@sonarsansar.com within 7 days from the receipt of the product.</li>
                    <li>Requests made after 7 days from the date of receiving the product will not be entertained.</li>
                    <li>Once the return package is ready, customers can call the customer service team who will arrange to pick it up from their doorstep.</li>
                </ul>
            </div>                      
    
            <h2 class="semibold-20">Refund</h2>
            <p class="regular-blue">We shall process the refund once we receive the product. The product must be in unused
                condition & unbroken condition, in its original packaging along with its original tags and invoice, failing
                which refund may not be possible.</p>
            <p class="regular-blue">Once the refund process has been initiated, the amount will be directly refunded to your
                account via the same mode within 10 working days from the receipt of the returned product(s) at the
                warehouse of Sonar Sansar.</p>
    
            <h2 class="semibold-20">Requirement For valid Refund</h2>
            <p class="regular-blue">The product must be in unused condition, in its original packaging along with its
                original tags and invoice, failing which refund may not be possible.</p>
    
            <h2 class="semibold-20">Refund TAT (Turn around Time)</h2>
            <p class="regular-blue">Once the refund process has been initiated, the amount will be directly refunded to your
                account through NEFT/RTGS within 7-10 working days from the day of receipt of the returned product(s) at the
                warehouse of Senco Gold.</p>
        </section>
    
        <section>
            <h2 class="semibold-20">Bokavar / Hiranmayi Jewelry Purchase Schemes</h2>
            <div class="regular-blue">
                <ol>
                    <li>It is only value deposit. No gold booking at the time of paying instalment.</li>
                    <li>Gold rate applicable on the day of sale invoice.</li>
                    <li>Minimum enrollment amount is ₹500/- and multiple of ₹500/- thereafter.</li>
                    <li>Instalment can be paid or redeemed at any company stores as of now.</li>
                    <li>There are two jewelry purchase schemes, Bokavar for 12 months in the form of money deposit, / Hiranmayi for 12 months in the form of gold deposit schemes respectively.</li>
                    <li>Customer has to maintain the same instalment amount throughout the scheme.</li>
                    <li>30% of one instalment will be passed on to the customer as a benefit of discount when making a sale invoice for a 6-month scheme if the customer completes the scheme successfully without any default/delay payment.</li>
                    <li>100% of one instalment will be passed on to the customer as a benefit of discount when making a sale invoice for a 12-month scheme if the customer completes the scheme successfully without any default/delay payment.</li>
                    <li>Customer has to mandatorily redeem the account within 60 days from the date of maturity.</li>
                    <li>Payment of instalments after due dates will be considered as a default, for that particular month, and the eligible discount would be reduced proportionately.</li>
                    <li>No benefit in any form shall be provided for schemes where the scheme has been operated for less than 6 months or less than 6 instalments have been paid to the company.</li>
                    <li>If the redemption is requested before the maturity of the scheme, the "12 months scheme" will be eligible for discount under the "6 months scheme" provided T&C of 6 months scheme have been complied.</li>
                </ol>
            </div>
            
        </section>
    
        <section>
            <h2 class="semibold-20">EASY RETURNS</h2>
            <p class="regular-blue">We strive to provide you with an experience that makes you feel confident in your
                purchase and use of our products and services. We hope your selection brings you many years of enjoyment.
            </p>
            <p class="regular-blue"><strong>GOLD AND SILVER COINS ARE NOT ELIGIBLE FOR RETURN.</strong></p>
    
            <h2 class="semibold-20">How to Initiate the Refund Process?</h2>
            <div class="regular-blue">
                <ol>
                    <li>The customer can return the product in case of the wrong size being ordered, product got damaged on delivery within 7 days from the date of delivery. Customers should contact Senco Gold & Diamonds customer support at <span class="highlight"><a href="tel:18001030017">1800-103-0017</a></span> or write to <span class="highlight"><a>customerfeedback@sencogold.co.in</a></span>.</li>
                    <li>We shall not entertain any return request made after 7 days from the date of receiving the product.</li>
                    <li>In case if you want to return the product, please mail us at <span class="highlight"><a>customerfeedback@sencogold.co.in</a></span> or call our customer service helpline number: <span class="highlight"><a href="tel:18001030017">1800-103-0017</a></span>, and we will arrange reverse pick up at your doorstep.</li>
                </ol>
            </div>
            
    
            <h2 class="semibold-20">Refund</h2>
            <p class="regular-blue">We shall process the refund once we receive the product intact.</p>
    
            <h2 class="semibold-20">Requirement For Valid Refund</h2>
            <p class="regular-blue">The product must be in unused condition, in its original packaging along with its
                original tags and invoice, failing which refund may not be possible.</p>
    
            <h2 class="semibold-20">Refund TAT (Turnaround Time)</h2>
            <p class="regular-blue">Once the refund process has been initiated, the amount will be directly refunded to your
                account through NEFT/RTGS within 7-10 working days from the day of receipt of the returned product(s) at the
                warehouse of Senco Gold.</p>
        </section>
    </div>
</div>
















<!--
  <style>
      .faClass{
    text-align: center;
    padding-top: 20px;
    float: center;

  font: Regular 25px/23px Poppins;
  letter-spacing: 0px;
  color: #AAA6BC;
  font-size: 30px;
  }
  </style> -->