import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DashboardService, ShopModel } from '../dashboard/dashboard.service';
import { getStoreName } from '../services/dataStore.service';
import { injectClarity } from '../services/scriptInjection.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  COMPANY_NAME=getStoreName();

  shopModel = new ShopModel();

  addInfo : string = "";

  constructor(private location:Location,
    private service: DashboardService) { }

  ngOnInit(): void {
    this.service.getShop().subscribe((resp:ShopModel)=>{
      this.shopModel=resp;
      console.log(this.shopModel);
      injectClarity(resp.clarity_id);
      this.COMPANY_NAME=getStoreName();
      if(this.shopModel.id == '14') {
        this.addInfo = "Trade Licence: HMC/W5/68048/23" + "\n" + "BIS Registration: HM/C-5100098090" + "\n" + "UDYAM Registration :  UDYAM-WB-08-0007119";
      }
      this.shopModel.updateShop();
  },
            (err)=>{});

  }


  back(){
    this.location.back();
  }

}
