import { ConditionalExpr } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { HeaderInterceptor } from '../services/header.interceptor';
import { StatusService } from './status-page.service';
import { PreviousrouteService } from '../services/previousroute.service';
import { AnalyticService } from '../services/analytics.service';
import { filter, pairwise, startWith, tap } from 'rxjs';
import { MetapixelService } from '../services/metapixel.service';
declare var fbq: Function;

@Component({
  selector: 'app-status-page',
  templateUrl: './status-page.component.html',
  styleUrls: ['./status-page.component.css'],
  providers: [StatusService,AnalyticService,MetapixelService]
})
export class StatusPageComponent implements OnInit {
  IS_BACK_BUTTON_ENABLED = false;
  animationOptions: AnimationOptions = { path: "" };
  title = "";
  description = "";
  order_id = "";
  status?: string | null;

   previousUrl: string = '';
   currentUrl: string = '';

  constructor(private route: ActivatedRoute,
    private router: Router,
    private service: StatusService,
    private pRoute:PreviousrouteService,
    private analytics:AnalyticService,
    private pixel:MetapixelService) { 
  
  
    
    }

  showSuccess(title = "Payment Successful !",
    description = "Your payment has been successfuly received.",
    animation_path = "/assets/lottie/success.json") {

    this.animationOptions = { path: animation_path };
    this.title = title;
    this.description = description;
  }

  showFailure(title = "Payment failed!",
    description = "Sorry to know that your payment failed!",
    animation_path = "/assets/lottie/fail.json") {
      this.animationOptions = { path: animation_path };
      this.title = title;
      this.description = description;
     }

  setupPage() {    
    if (this.status == '1') 
    {
      try{
       var previousUrl= localStorage.getItem('previousUrl');
        if(previousUrl=="/page/savings-toc" || previousUrl=="/page/savings-plan-cust-data")
        {
          this.analytics.logEvent("savings-success-v2");
          this.pixel.trackCustomEvent("savings-success-v2",{});
        }
        if(previousUrl=='/dashboard/home')
        {
          this.analytics.logEvent("emi-success-v2");
          this.pixel.trackCustomEvent("emi-success-v2",{});
        
        }
      }
      catch(error){
        console.error('An error occurred:', error);
      }
     
      this.showSuccess();
    
    }

    else if (this.status == '-1') {
      this.animationOptions = { path: '/assets/lottie/processing_round.json' };
      this.title = "Processing!";
      this.description = "Checking payment status.";
      var order_id = this.route.snapshot.paramMap.get("order_id");
      console.log(order_id);

      if (order_id != null) {
        this.service.checkPaymentStatus(order_id).subscribe(resp => {
          if (resp.is_successful)
          {
            this.status='1';
            this.showSuccess("Payment successful!",resp.message,"/assets/lottie/success.json");

          }
          else
          {
            this.status='0';
            this.showFailure("Payment failed !",resp.message,"/assets/lottie/fail.json")

          }

        });

      }
    }
    else this.showFailure();


  }

  

  ngOnInit(): void {

    this.status = this.route.snapshot.paramMap.get("status");
    if(this.status == null)
    {
      this.status=this.route.snapshot.paramMap.get("is_successful");
    }
    console.log("status recieved"+this.status);
    this.setupPage();
    window.onbeforeunload = () => this.ngOnDestroy();

  }

  ngOnDestroy(){
    this.router.navigate(['/dashboard/home']);
  }

  btnClick() {
    if(localStorage.getItem("access")==null)
      window.location.href="https://vtm-walajabad.app.link/test/medium";
    else
      this.router.navigate(['/dashboard/']);
  }

}
