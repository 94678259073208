import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'jewellers_sip';
  display_page_code:number=0;
  display_in_frame=false;

  selectPageToDisplay(){
    let path = window.location.pathname;
    if(path.search("dashboard")>=0)
    {this.display_page_code=1;return;}
    this.display_page_code=0;return;

  }
  message!:any;
  constructor(private router:Router){

  }

  ngOnInit() {
    
      this.selectPageToDisplay();
      if(window.innerHeight<window.innerWidth){
        this.display_in_frame=true;
      }
      else{
        this.display_in_frame=false;
      }

      }



  
  onActivate(event:any){
  //   // Scroll the screen to top
  //   window.scroll({ 
  //     top: 0, 
  //     left: 0, 
  //     behavior: 'smooth' 
  // });
  }


}
