import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CashFree } from '../services/cashfee.service';
import { injectCashfreePaymentScript } from '../services/scriptInjection.service';
import { WindowRefService } from '../window-ref.service';
@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.css']
})
export class PaymentPageComponent implements OnInit {
  // THIS IS A NO AUTHENTICATION PAGE, WHICH TAKES IN ORDER_ID AND CF_ID
  // AND INITIATES THE PAYMENT
  // WHY: THIS PAGE IS USED FOR THE APP TO CALL
  constructor(
    private route: ActivatedRoute,
    private winRef: WindowRefService)
    {

    }

  ngOnInit(): void {
    injectCashfreePaymentScript();
    if(this.route.snapshot.paramMap.has("order_id") && this.route.snapshot.paramMap.has("cf_id"))
    {
      var order_id = this.route.snapshot.paramMap.get("order_id");
      var cf_session = this.route.snapshot.paramMap.get("cf_id");
      if (order_id && cf_session)
        new CashFree(this.winRef).pay(order_id,cf_session);
      else { console.warn("order id and cf session id not found");}
      }
  }



}
