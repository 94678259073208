
export function communicateLogo( logo_url:string|null|undefined)
{
  console.log("started");
  console.log( JSON.stringify({type:"9",
          logo:logo_url,
        }));
}

export function launchNativeRazorpay(){}
