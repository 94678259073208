import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HeaderInterceptor } from '../services/header.interceptor';
import { getSubDomain } from '../services/dataStore.service';
import { Observable, take } from 'rxjs';

export interface TokenModel {
  access: string;
  refresh: string;
}


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  send_otp_url = environment.ip + "/app/sendOtp/";
  login_url = environment.ip + "/app/login/";
  re_send_otp_url = environment.ip + "/app/retryOtp/";


  constructor(private http: HttpClient) {

  }

  sendOtp(fd: FormData) {
    return this.http.post(this.send_otp_url, fd);

  }

  resendOtp(fd: FormData) {
    return this.http.post(this.re_send_otp_url, fd);

  }

  login(mobile: string, otp: string) {
    let fd = new FormData();
    fd.append("mobile", mobile);
    fd.append("otp", otp);
    fd.append("subdomain", getSubDomain());
    return this.http.post<TokenModel>(this.login_url, fd);

  }

  isLoggedIn(token: string) {
    // If status is 200 then token is working, if not fail
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', "Bearer " + token);
    return this.http.post(`${environment.ip}/app/checkToken/`, {}, { headers });
  }

  refreshAccessToken() {
    var token = localStorage.getItem("refresh");
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', "Bearer " + token);
    return this.http.post(`${environment.ip}/app/refresh/`, { "refresh": HeaderInterceptor.getRefreshToken }, { headers });
  }

  getDGStatus(): Observable<HttpResponse<any>> {
    let access = localStorage.getItem("access")
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + access
    });
    return this.http.get<HttpResponse<any>>(`${environment.ip}/shop/digital_coin/setup/`, { observe: 'response', headers: headers }).pipe(take(1))
  }

}
