import { environment } from "src/environments/environment";



export function getStoreName(){
  return localStorage.getItem("shop_name");
}


export function getSubDomain(){
  if(location.hostname.toLowerCase().indexOf(environment.sub_domain)>=0)
    return location.hostname.split(".")[0];
  else if (location.hostname.toLocaleLowerCase() == "sumangalipallipat.com")
    return "sumangali";
  else
    return location.hostname.split(".")[1]; // return the actual domain, this is used for externally hosted projects
}


export function getClarityId(){
  return localStorage.getItem("clarity_id");
}


export function saveJewelleryCategoryData(data:any){
  return localStorage.setItem("jewellery_category_data",data);
}

export function getJewelleryCategoryLastSavedTime(){
  return localStorage.getItem("category_last_saved_on");
}
