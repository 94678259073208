import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Rest} from "src/app/model/restModel";
import { getSubDomain } from '../services/dataStore.service';
import { communicateLogo } from '../services/phone-communication.service';
class Asset{
  file?:string|null;
  file_l?:string|null;
  file_xl?:string|null;
  file_xxl?:string|null;
  icon?:string|null;
}

export class ShopModel{
    id?:string|null;
    name?:string|null;
    logo?:Asset
    clarity_id?:string|null;
    actual_name!:string;
    pincode!:string;
    address!:string;
    state!:string;
    customer_care_number!:string;
    gstin!:string;


     getName(){return localStorage.getItem("shop_name");}
     getClarityId(){return localStorage.getItem("clarity_id");}
     getIcon(){return localStorage.getItem("shop_icon_icon"); }
     getId(){return localStorage.getItem("shop_id");}
     getState(){return localStorage.getItem("shop_state");}
     getCustomerCareNumber(){return localStorage.getItem("shop_customer_care_number");}
     getAddress(){return localStorage.getItem("shop_address");}
     getPincode(){return localStorage.getItem("shop_pincode");}
     getActualName(){return localStorage.getItem("shop_actual_name");}
     getGstin(){return localStorage.getItem("shop_gstin");}

    setIconData(data?:Asset|null){
      communicateLogo(data?.icon);
      if(data){
        if(data.file)
        localStorage.setItem("shop_icon_file",data.file);
        if(data.file_xl)
        localStorage.setItem("shop_icon_file_xl",data.file_xl);
        if(data.file_xxl)
        localStorage.setItem("shop_icon_file_xxl",data.file_xxl);
        if(data.file_l)
        localStorage.setItem("shop_icon_file_l",data.file_l);
        if(data.icon)
        localStorage.setItem("shop_icon_icon",data.icon);
      }
    }

    setId(id?:string|null){if(id!=null)localStorage.setItem("shop_id",id);}
    setName(name?:string|null){if(name)localStorage.setItem("shop_name",name);}
    setIcon(icon?:string|null){if(icon)localStorage.setItem("shop_icon",icon);}
    setClarityId(clarity_id?:string|null){if(clarity_id)localStorage.setItem("clarity_id",clarity_id);}
    updateShop(){
      this.setName(this.name);
      this.setIconData(this.logo);
      this.setClarityId(this.clarity_id);
      if(this.customer_care_number!=null)
        localStorage.setItem("shop_customer_care_number",this.customer_care_number);
      if(this.address!=null)
        localStorage.setItem("shop_address",this.address);
      if(this.pincode!=null)
        localStorage.setItem("shop_pincode",this.pincode);
      if(this.state!=null)
        localStorage.setItem("shop_state",this.state);
      if(this.actual_name!=null)
        localStorage.setItem("shop_actual_name",this.actual_name);
      if(this.gstin!=null)
        localStorage.setItem("shop_gstin",this.gstin);
    }
  }

@Injectable({
    providedIn: 'root'
  })
export class DashboardService {
  static shopModel:ShopModel


  constructor(public http:HttpClient) {}

  getShop(){
    return this.http.get<ShopModel>(`${environment.ip}/app/shop/?subdomain=${getSubDomain()}`)

  }

}
