import { environment } from "src/environments/environment";

export function injectClarity(clarity_id?:string|null)
{
  console.log("clarity injector");
  let clarity_script=document.getElementById("clarity_script");
  if(clarity_script==null && clarity_id!=undefined && clarity_id!=null && clarity_id.trim().length>0)
  {
    console.log("injecting clarity");
    let node = document.createElement('script');
    node.type = 'text/javascript';
    node.innerHTML=` (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "${clarity_id}");`
  node.setAttribute("id","clarity_script");
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  else
  {
    console.log("clarity id already available"+clarity_script);
  }

}


export function injectCashfreePaymentScript()
{
  let node = document.createElement('script');
  node.src = environment.cashfree_url;//Change to your js file
  node.type = 'text/javascript';
  node.async = true;
  node.charset = 'utf-8';
  document.getElementsByTagName('head')[0].appendChild(node);
}
