import { Injectable } from '@angular/core';
import { getSubDomain } from './dataStore.service';
declare var fbq: Function; // Declare the Facebook Pixel function

@Injectable({
  providedIn: 'root'
})
export class MetapixelService {

  constructor() { 
    fbq('init', '748396793595135');
  }

    
    // Example function to track page view
    trackPageView(page:string) {
      if(getSubDomain()=='sumangali' || getSubDomain()=='localhost')
      {
        fbq('track', page);
      }
      else{
        return
      }
    }
  
    // Example function to track a custom event
    trackCustomEvent(eventName: string, eventData?: any) {
      if(getSubDomain()=='sumangali' || getSubDomain()=='localhost')
      {
        fbq('track', eventName, eventData);
      }
      else{
        return
      }
    }

    
}
