import {WindowRefService} from '../window-ref.service';
import { environment } from 'src/environments/environment';
import { ShopModel } from '../dashboard/dashboard.service';
export class RazorPay{
    razorpay_order:string;
    winRef:any;

    constructor(razorpay_order:string,
         winRef:WindowRefService){
        this.razorpay_order=razorpay_order;
        this.winRef=winRef;
    }

    pay(cost_in_paise:string,
        success_function:any,
        fail_function:any,
        email?:any|null,
        phone?:any|null,
        name?:any|null,) {

        if(localStorage.getItem("is_mobile")!=null)
        {
          var mobile_version=localStorage.getItem("mobile_app_version");
          console.log(mobile_version!=null);
          if(mobile_version!=null && parseInt(mobile_version)>=9)
           {
            console.log(parseInt(mobile_version)>=9);

            console.log(JSON.stringify({"type":"5",
                        "gateway_type":"0",
                        "phone":phone,
                        "email":email,
                        "razorpay_key":environment.razorpay_key,
                        "order_id":this.razorpay_order,
                        "success_url":`${window.location.origin}/spg/status;status=1`,
                        "fail_url":`${window.location.origin}/spg/status;status=0`
                        }));
              return;
            }
        }

        var shopModel=new ShopModel();
        const options: any = {
          key: environment.razorpay_key,
          amount: cost_in_paise, // amount should be in paise format to display Rs 1255 without decimal point
          currency: 'INR',
          name: shopModel.getName(), // company name or product name
          description: '',  // product description
          image: shopModel.getIcon(), // company logo or product image
          order_id: this.razorpay_order, // order_id created by you in backend
          modal: {
            // We should prevent closing of the form when esc key is pressed.
            escape: false,
          },
          prefill:{
            name:name,
            email:email,
            contact:phone
          },
          notes: {
            // include notes if any
          },
          theme: {
            color: '#0c238a'
          },
          'config': {
            'display': {
              'hide': [
                {'method': "netbanking"},
                {'method': "wallet"},
                {'method': "card"}
              ],
            }
          },
        hidden:{email:true}

        };
        options.handler = ((response: any, error: any) => {
          options.response = response;
          success_function();
          // call your backend api to verify payment signature & capture transaction
        });
        options.modal.ondismiss = (() => {
          // handle the case when user closes the form while transaction is in progress
          console.log('Transaction cancelled.');
          fail_function();
        });
        const rzp = new this.winRef.nativeWindow.Razorpay(options);
        rzp.open();
      }
}
