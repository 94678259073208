<div class="custom-nav mb-2">
    <button class="mr-2" mat-icon-button (click)="back()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <span class="bold-small">Privacy policy</span>
</div>
<div *ngIf="shopModel.id!=null">
    <div *ngIf="shopModel.id != '14'" class="container">
        <h1 class="bold-25 center">Privacy Policy &mdash; {{COMPANY_NAME}}</h1>

        <h2 class="semibold-20">Introduction:</h2>



        <p class="regular-blue">
            At {{COMPANY_NAME}}, your privacy and trust are secured and given utmost importance.
            This Privacy Policy is carefully made to help you understand how your data is received, stored and used by
            {{COMPANY_NAME}}.
            You expressly consent to use & disclose your personal information in accordance with this Privacy Policy by
            logging into any of {{COMPANY_NAME}}&apos;s platforms on any medium.
            Note: Our privacy policy may change at any time without prior notification. Kindly review the privacy policy
            to
            be updated with this Privacy Policy. It shall apply uniformly against all of {{COMPANY_NAME}}&apos;s mode of
            transmission of portal.
        </p>





        <h2 class="semibold-20">General:</h2>

        <p class="regular-blue">




            {{COMPANY_NAME}} warrants and covenants not to sell, rent or share for benefit, any of the personal data of
            the
            user so as to make it available for subscription for promotional advertisements/unsolicited enquiries by way
            of
            emails/calls.
            {{COMPANY_NAME}} further warrants that any email/call from it shall only be in connection with its services
            as
            mentioned in the terms and policy.
            {{COMPANY_NAME}} may reveal general statistical information about {{COMPANY_NAME}} & its users, on the
            average
            traffic on its website along with purchase trend on {{COMPANY_NAME}} etc.
            All the legal compliant requests for disclosures of personal data shall be accepted by {{COMPANY_NAME}} and
            it
            shall not amount to violation of privacy of the user.

        </p>


        <h2 class="semibold-20">
            Personal Data:

        </h2>


        <p class="regular-blue">

            Personal Information means and includes all information such as name, address, mailing id, telephone number,
            all
            the details on the credit/debit card , UPI , account details , information about mobile phone and any other
            details that may have been voluntarily provide by the user in connection with availing any of the services
            on
            {{COMPANY_NAME}}. In addition, information regarding the domain, server, host providing the internet. IP
            address
            of the system/ISP and any other anonymous site data may be accessed by {{COMPANY_NAME}}
        </p>
        <h2 class="semibold-20">
            Use of Personal Information
        </h2>

        <p class="regular-blue">

            We use personal information to provide you with the below:
            a. To resolve any technical snag, troubleshoot concerns, promote safe services, to perform financial
            transactions if any, measure consumer statistics in our services,
            b. To inform you about offers, products, services, updates, customize your experience, detect & protect us
            against error, fraud and other criminal activity, enforce our terms and conditions, etc.
            c. To send you offers based on your previous orders and interests.
            d. To customize your experience at {{COMPANY_NAME}}, by providing you with content that we think you might
            be
            interested in and to display content according to your preferences.
        </p>

        <h2 class="semibold-20">
            Cookies
        </h2>
        <p class="regular-blue">

            A &quot;cookie&quot; is a small piece of information stored by a web server on a web browser so it can be
            later
            read back from that browser. No personal information will be collected via cookies and other tracking
            technology; however, if you previously provided personally identifiable information, cookies may be tied to
            such
            information. Aggregate cookie and tracking information may be shared with third parties.

        </p>
        <h2 class="semibold-20">
            Security
        </h2>
        <p class="regular-blue">

            {{COMPANY_NAME}} has installed a secure server with stringent security measures in place to safeguard
            user&apos;s personal data from misuse, destruction and alteration of the information. Once your information
            is
            in our possession we adhere to strict security guidelines, protecting it against unauthorized access.

        </p>

        <h2 class="semibold-20">
            Links to Other Sites
        </h2>
        <p class="regular-blue">

            {{COMPANY_NAME}} may have linked up with a few websites to carry out the functions at its optimum.
            Therefore, is
            not responsible for the privacy policy or the content of the other websites linked/to be linked on
            {{COMPANY_NAME}}.
        </p>

        <h2 class="semibold-20">
            Consent
        </h2>
        <p class="regular-blue">

            By using {{COMPANY_NAME}} and/or by providing your information, you consent to the collection, storage and
            use
            of the information you disclose on {{COMPANY_NAME}} in accordance with this Privacy Policy, including but
            not
            limited to your consent for sharing your information as per this privacy policy.
        </p>





    </div>

    <!-- // custom content only for sonar sansar id 14 -->
    <div *ngIf="shopModel.id == '14'" class="container">
        <h1 class="bold-25 center">Privacy</h1>

        <h2 class="semibold-20">INTRODUCTION</h2>
        <p class="regular-blue">
            Sonar Sansar® a unit of Paul Jewellery House Private Limited, a private limited company with its registered
            office at 12,13,14 Sri Aurobinda Road, Gr Floor Salkia Howrah - 711106, West Bengal, India is committed to
            protecting your privacy.
            We know that you care how information about you is used and shared. We appreciate your trust and use your
            information as per the applicable laws in India. This Privacy Notice (the “Notice”) describes how Sonar
            Sansar®
            and its affiliates (collectively referred to as “Company”, “We”, “Us”, “Our” or “SONAR SANSAR®”) collect and
            process your personal information (defined below) through SONAR SANSAR® websites, devices, products,
            services,
            online marketplace and applications that reference this notice.
        </p> <br>
        <p class="regular-blue">
            This notice shall apply to the Users (defined below) of this website and the App (the website and the App
            collectively referred to as “Platform”) and when you use our services on this platform you agree to our use
            of
            your personal information, including sensitive personal information data or information (“SPDI”) in
            accordance
            with this notice, as may be amended from time to time by us, at our discretion. You also agree and consent
            to us
            collecting, storing, processing, transferring, and sharing your personal information, including SPDI with
            third
            parties or service providers for the purposes set out in this notice.
        </p> <br>
        <p class="regular-blue">
            For the purposes of this notice the users of this platform shall be referred to as “Users”, “You”, “Your” or
            “Yourself”.
            Please ensure that this notice is perused by you before availing any services from us in connection with
            purchase of jewellery sold by us (“Our Services” or the “Services”). This notice shall be updated from time
            to
            time, pursuant to our Privacy Policy and therefore in order to stay abreast with the purpose of using your
            information and protecting your privacy, we recommend you to please keep reviewing this notice.
        </p> <br>
        <p class="regular-blue">
            When you use our platform, please note that our platform may contain links to other third-party websites.
            OUR
            PRIVACY NOTICE DOES NOT APPLY TO INFORMATION THAT YOU PROVIDE TO, OR THAT IS COLLECTED BY, ANY THIRD-PARTY
            THROUGH OUR WEBSITE AND / OR APP, AND ANY THIRD-PARTY WEBSITES THAT YOU ACCESS OR USE IN CONNECTION WITH THE
            SERVICES OFFERED THROUGH OUR WEBSITE AND / OR APP. THE COMPANY SHALL NOT BE LIABLE FOR AND HAS NO CONTROL
            OVER
            THE PRACTICES AND CONTENT OF ANY SUCH THIRD-PARTY WEBSITES.
            This notice is incorporated into and subject to our Privacy Policy and Terms of Use (“User Terms”). It shall
            be
            read harmoniously and in conjunction with the Privacy Policy and User Terms.
            All capitalised items not defined in this notice shall bear the same meaning, along with its cognate
            expressions
            and grammatical variations, as given in the Privacy Policy
        </p>

        <h2 class="semibold-20">PURPOSE OF COLLECTION AND USAGE</h2>
        <p class="regular-blue">
            We use your personal information to operate, provide, develop and improve the products and services that we
            offer users.
        </p>
        <h2 class="semibold-20">Categories of Information We Process</h2>

        <p class="regular-blue">We may collect the following categories of information from you:</p>

        <h2 class="semibold-20">1. Personal Information</h2>
        <p class="regular-blue">We may collect the personal information from you such as your name, gender, email
            address, mobile and/or
            telephone numbers, service address, date of birth, profile picture, contact details of people you refer to
            us
            and other information about the service address that you give us, information provided by you while
            initiating a
            transaction, including and without limitation, search words, location, products or services being sought,
            reviews, rating, order details and history, pictures of items ordered or for which a task is run and all
            chat
            history, etc.</p>

        <h2 class="semibold-20">2. Sensitive Personal Data or Information</h2>
        <p class="regular-blue">We may collect sensitive personal data or information such as passwords and financial
            information such as PAN
            details (“Financial Information”), to process payments for some of our services. We do not ask you for any
            financial information, including credit card account data or other payment data, to process payments for our
            services, which is handled by the respective payment gateways. We do not store any financial information
            provided to us. However, for transactions above INR 2,00,000 (Indian Rupees Two Lakhs only), we store the
            PAN
            details of the users.</p>

        <h2 class="semibold-20">How We Use Personal Data</h2>
        <div class="regular-blue">
            <ul>
                <li>To fulfil orders: We use your personal information to take and fulfil orders, deliver products and
                    services, process payments, and communicate with you about orders, products and services and
                    promotional offers;</li>
                <li>Provide, troubleshoot, and improve Services: We use your personal information to provide
                    functionality, analyse performance, fix errors, and improve the usability and effectiveness of our
                    services;</li>
                <li>Recommend and specialise: We use your personal information to recommend features, products, and
                    services that might be of interest to you, identify your preferences, and personalize your
                    experience with our services;</li>
                <li>Provide voice, image and camera services: When you use our voice, image and camera services, we use
                    your voice input, images, videos and other personal Information to respond to your requests, provide
                    the requested service to you, and improve our services;</li>
                <li>Comply with legal obligations: In certain cases, we collect and use your personal Information to
                    comply with laws;</li>
                <li>Communicate with You: We use your personal Information to communicate with you in relation to our
                    services via different channels (e.g., by phone, e-mail, chat);</li>
                <li>Prevent Fraud and Credit Risks: We use your personal Information to prevent and detect fraud and
                    abuse in order to protect the security of our customers, SONAR SANSAR® and others, whose rights and
                    freedom are prejudicially affected;</li>
                <li>Respond to your comments, reviews and questions, provide customer support and better service;</li>
                <li>Communicate important notices or changes to the Services provided by SONAR SANSAR® on the Platform,
                    use of the platform and the terms/policies which govern the relationship between You and the SONAR
                    SANSAR®;</li>
                <li>Undertake a task to fulfil any other purpose with your consent.</li>
            </ul>
        </div>


        <h2 class="semibold-20">USE OF COOKIES</h2>
        <p class="regular-blue">
            We also use cookies on our platform to enhance your user experience of our Platform. We use browser cookies
            to
            tell us, for example, whether you’ve visited us before or if you’re a new visitor and to help us identify
            website features in which you may have the greatest interest. Browser cookies may enhance your online
            experience
            by saving your preferences while you are visiting a particular website. The “help” portion of the toolbar on
            most browsers will tell you how to stop accepting new browser cookies, how to be notified when you receive a
            new
            browser cookie, and how to disable existing browser cookies. Remember though, without browser cookies, you
            may
            not be able to take the full advantage of every website feature.
        </p>

        <h2 class="semibold-20">DATA SHARE</h2>
        <p class="regular-blue">
            We share your information with third parties and third-party service providers, promotion and marketing
            partners, consultants, business partners including merchants, who support our business, to carry out your
            service requests and fulfil such service requests in connection with our services.
        </p>

        <p class="regular-blue">
            We may also share your personal Information including financial information with third party payment service
            providers like payment gateways, payment aggregator and other payment service providers for the purpose of
            fulfilling services to you.
        </p>

        <p class="regular-blue">
            If you provide a mobile phone number and/or e-mail address, SONAR SANSAR® and/or vendors responsible for
            customer service may call you or send you communications in relation to your use of the platform or any
            transaction initiated by you on the platform. SONAR SANSAR® will share/transfer some or all of the collected
            information, personal Information or otherwise, with another business entity should we (or our assets) plan
            to
            merge with, or be acquired by that business entity, or reorganization, amalgamation, restructuring of
            business,
            in connection with or during negotiation of any merger, financing, acquisition, bankruptcy, dissolution,
            transaction or proceeding. Should such a transaction occur, that other business entity or the new combined
            entity, will be contractually bound to comply with the terms of this Policy.
        </p>

        <p class="regular-blue">
            We do not disclose personal Information about identifiable individuals to advertisers, but we may provide
            them
            with aggregate and/or anonymized information about you and other registered users, to help advertisers reach
            the
            kind of audience they want to target.
        </p>

        <h2 class="semibold-20">REGULATORY OBLIGATIONS</h2>
        <p class="regular-blue">
            We may be mandated to share your personal Information with any legal, regulatory and / or law enforcement
            agencies in the event of regulatory inquiries / investigation / or any reporting requirement under different
            statutes or any other statutory obligations under applicable laws in India.
        </p>

        <h2 class="semibold-20">DATA SECURITY</h2>
        <p class="regular-blue">
            We are committed to protecting your Personal Information in our custody. We take reasonable steps to ensure
            appropriate physical, technical and managerial safeguards are in place to protect your personal Information
            from
            unauthorized access, alteration, transmission and deletion. We use encryption at relevant points to protect
            your
            personal information. We have a process for regularly testing the effectiveness of our technical and
            organizational measures for ensuring the security of the processing. We ensure that the service
            providers/third
            party vendors provide services to us under appropriate confidentiality agreements and are required to take
            appropriate security measures to protect your personal Information in line with our policies.
        </p>

        <h2 class="semibold-20">RETENTION PERIOD</h2>
        <p class="regular-blue">
            We retain your personal information for such period as required under the relevant laws, as applicable from
            time
            to time. However, we may retain data related to you if there is a legal obligation to retain the data; if
            required to comply with any applicable statutory or regulatory retention requirement by law; if we believe
            it
            may be necessary to prevent fraud or future abuse; to enable Company to exercise its legal rights and / or
            defend against legal claims; or for other legitimate purposes. We may continue to retain your data in
            anonymous
            form for analytical and research purposes.
        </p>

        <h2 class="semibold-20">USER’S RIGHTS W.R.T. DATA PROTECTION AND PRIVACY:</h2>
        <div class="regular-blue">
            <ul>
                <li>You have the right:</li>
                <li>To provide consent for us to collect your personal Information;</li>
                <li>To ask us to provide you access to your information that we hold;</li>
                <li>To have your personal Information rectified, if it is inaccurate or incomplete;</li>
                <li>To withdraw your consent, in writing at any point in time;</li>
                <li>To request the deletion or removal of personal data where there is no compelling reason for its
                    continued processing;</li>
                <li>To restrict our processing of your personal Information (i.e. permitting its storage but no further
                    processing);</li>
                <li>To object to direct marketing, including profiling, and processing for the purposes of scientific /
                    historical research and statistics;</li>
                <li>To complain to the Grievance Officer if you are of the view that your personal Information is not
                    processed in accordance with the purposes as disclosed in this notice;</li>
                <li>Not to be subject to decisions based purely on automated processing where it adversely affects you,
                    whether legally or otherwise.</li>
            </ul>
        </div>

        <p class="regular-blue">
            To exercise these rights with respect to Your Personal Information, you may reach out to Us at the details
            given
            under the ‘Contact Us’ section below.
        </p>



        <h2 class="semibold-20">RIGHT TO LODGE A COMPLAINT</h2>
        <p class="regular-blue">
            In the event, you are of the view that our personal Information is not processed in accordance with the
            purposes
            as disclosed in this notice, you also have a right to lodge a complaint with the Grievance Officer, contact
            details of which are provided below:
        </p>

        <address class="regular-blue">
            <strong>Mr. Tamal Paul</strong><br>
            Office for Grievance Redressal,<br>
            Sonar Sansar a unit of Paul Jewellery House Private Limited<br>
            Sonar Sansar ®<br>
            12,13,14 Sri Aurobinda Road Salkia<br>
            Howrah: 711 106<br>
            <strong>Phone:</strong> <a href="tel:03326556022">033 2655 6022</a> / <a href="tel:+918447746337">+91
                8447746337 Ext: Complain</a><br>
            <strong>Email:</strong> <a>cc@sonarsansar.com</a>

        </address>

        <h2 class="semibold-20">CONTACT US</h2>
        <p class="regular-blue">
            For any further queries related to privacy of your personal Information, your rights under applicable laws
            in
            India or concerns regarding this notice, you could reach us at:
        </p>

        <address class="regular-blue">
            <strong>Contact Email Address:</strong> <a>cc@sonarsansar.com</a><br>
            <strong>Contact No.:</strong> <a href="tel:+918447746337">+91-8447746337</a>            
        </address>

        <p class="regular-blue">
            We will take reasonable steps to resolve your queries at the earliest. However, in any event, we will
            provide
            you the response within a period of 1 (one) month from the receipt of such requests. In case we are unable
            to
            address your concern within the said period, we will communicate the same along with the reason for the
            delay
            during the said period.
        </p>

        <h2 class="semibold-20">NOTIFICATION OF CHANGES</h2>
        <p class="regular-blue">
            We reserve the right to amend this Privacy Notice at any time without specifically notifying you of such
            changes. Any changes or updates will be effective immediately. You should review this Privacy Notice,
            published
            on the website at <a>https://sonarsansar.com/</a>, regularly
            for
            changes. YOU CAN DETERMINE IF CHANGES HAVE BEEN MADE BY CHECKING THE “LAST UPDATED” LEGEND ABOVE. If We make
            any
            significant changes, we will endeavor to provide you with reasonable notice of such changes, such as via a
            prominent notice on the website and the App or to your email address on record with us and where required is
            applicable by an Indian law, we will obtain your consent. Your acceptance of the amended Privacy Policy and/
            or
            your continued use of our services after we publish or send a notice about our changes to this policy shall
            signify Your consent to such changes and be legally bound by the same.
        </p>
    </div>
</div>
















<!--
<style>
    .faClass{
  text-align: center;
  padding-top: 20px;
  float: center;

font: Regular 25px/23px Poppins;
letter-spacing: 0px;
color: #AAA6BC;
font-size: 30px;
}
</style> -->