import {WindowRefService} from '../window-ref.service';
import { environment } from 'src/environments/environment';
import { ShopModel } from '../dashboard/dashboard.service';
export class CashFree{

    winRef:any;

    constructor(winRef:WindowRefService){
        this.winRef=winRef;
    }

    pay(payment_session_id:string,
        order_id:string) {




      if(localStorage.getItem("is_mobile")!=null)
      {
        var mobile_version=localStorage.getItem("mobile_app_version");
        if(mobile_version!=null)
        {
          console.log("Mobile version is "+mobile_version);
          if(parseInt(mobile_version)>=9)
          {
            //9 is the version after which cashfree sdk was introduced to the app, before which version did not exist
            console.log(JSON.stringify({"type":"5",
            "gateway_type":"1",
            "phone":null,
            "email":null,
            "razorpay_key":null,
            "session_id":payment_session_id,
            "order_id":order_id,
            "success_url":`${window.location.origin}/status;status=1`,
            "fail_url":`${window.location.origin}/status;status=0`
            }));

            return;
          }
        }

      }
        if(environment.production)
          location.href="https://payments.jewellers.live/pg/pay.html?cf_session_id="+payment_session_id;
        else
          location.href="https://sip.1ounce.in/pg/pay.html?cf_session_id="+payment_session_id;

          // const cashfree = new this.winRef.nativeWindow.Cashfree(payment_session_id);
        // const dropinConfig = {
        //   components: [

        //       "upi-intent",
        //       "upi-collect"
        //   ],

        // }
        // cashfree.drop(document.getElementById("paymentForm"),dropinConfig);
        // cashfree.redirect();
      }
}
