<div style="margin-top:30px;">
  <h2 mat-dialog-title style="text-align:center;">{{title}}</h2>
  <div mat-dialog-content style="text-align:center;justify-content:center;">
    <div class="lottie-animation" style="height:40">
      <ng-lottie [options]="animationOptions">
      </ng-lottie>
    </div>
    <div class="regular-grey">{{description}}</div>
    <div style="display:flex;justify-content:center;" class="mt-5" >
      <button *ngIf="status =='1' || status == '0'" class="custom-dialog-btn gold-bg semibold-white w-60 p-4"  (click)="btnClick()">Go to homepage</button>
      <!-- <button mat-button  cdkFocusInitial>Ok</button> -->
    </div>

  </div>
</div>
