import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MetapixelService } from './metapixel.service';
// import { GoogleAnalyticsService } from 'ngx-google-analytics';



@Injectable({
    providedIn: 'root'
  })
export class AnalyticService{
    category_name="";
    constructor(private analytics:AngularFireAnalytics,
      private metaPixel:MetapixelService
      ){}

    setUserId(id:string)
    {
      if(environment.is_analytics_enabled)
          this.analytics.setUserId(id);
    }

    setAppVersion()
    {
      this.analytics.setUserProperties({app_version:environment.version});
    }

    log(event_name:string, event_params={})
    {
      console.log("Event name: "+event_name);
      if(environment.is_analytics_enabled)
      {
        console.log(event_name);
        this.analytics.logEvent(event_name,event_params);

      }
    }

    logEvent(event_name:string,event_params={}){
      console.warn("Event name: "+event_name);
      if(environment.is_analytics_enabled)
      {
        this.analytics.logEvent(event_name,event_params);
      }
    }

    logCurrencyEvent(event_name:string, amount:string)
    {
      if(environment.is_analytics_enabled)
        this.analytics.logEvent(event_name,{"currency":"INR",
                                            "value":amount});
    }

    }
