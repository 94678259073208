
<div class="custom-nav mb-2">
    <button class="mr-2" mat-icon-button (click)="back()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    <span class="bold-small">About us</span>
  </div>
  <div class="container">
    <h1 class="bold-25">About us </h1>




    <p class="regular-blue">
        We are your one-stop destination for making any given day a celebration. We understand that your jewels are more than just accessories; they are an extension of your personality and legacy. We believe in giving the best price to our customers, having said that we try to cut down the middle layers in the trade and inspire the brand to serve customers the value for their purchase. We provide Exquisite and Unique jewellery designs in Diamonds, Gold, Polki ,Pearls, Silver and other precious n semi-precious gemstones. We have a wide range of Jewellery catering to all occasions and we specialise in Wedding Jewellery for both the Groom and the Brides.Each piece of our Jewelry is BIS certified and Diamonds come with certificates issued by GIA or IGI Laboratories, India's foremost diamond certification agencies.
    </p>


    <br>
    <h1 class="bold-25">Contact us </h1>


    <p class="regular-blue">
      {{shopModel.actual_name}} <br>
      {{shopModel.address}} <br>
      {{shopModel.state}}-{{shopModel.pincode}} <br>
      Phone: <a href="tel:+91{{shopModel.customer_care_number}}">{{shopModel.customer_care_number}}</a> <br>
      GSTIN: {{shopModel.gstin}} <br>
      {{addInfo}}
    </p>





  </div>

















  <!--
  <style>
      .faClass{
    text-align: center;
    padding-top: 20px;
    float: center;

  font: Regular 25px/23px Poppins;
  letter-spacing: 0px;
  color: #AAA6BC;
  font-size: 30px;
  }
  </style> -->
