import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Rest} from "src/app/model/restModel";


class ResponseModel{
  is_successful!:boolean;
  message!:string;
}

@Injectable({
    providedIn: 'root'
  })
export class StatusService {



  constructor(public http:HttpClient) {}


  checkPaymentStatus(order_id:string){
    return this.http.post<ResponseModel>(`${environment.ip}/app/subscription/completeSubscription2/`
        ,{order_id:order_id});
  }



}
