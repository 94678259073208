import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  static accessToken = "";
  static refreshToken = "";
  refresh = false; // to prevent infinite loops
  constructor(private http: HttpClient,
    private router: Router) { }

  static setAccessToken(token?: string|null) {
    //sets the access token to localstorage
    if( token==null){localStorage.removeItem("access");}
    else{localStorage.setItem("access", token);}

    //everytime access token is set, if used on mobile, then send console log for savings notification token
    let is_mobile = localStorage.getItem("is_mobile");
    if(is_mobile)
    {
      console.log(JSON.stringify({
        type: "6" ,
        access: token
      }
    ));
    }
  }

  static setRefreshToken(token?: string|null) {
    if(token==null){localStorage.removeItem("refresh");}
    else{localStorage.setItem("refresh", token);} }

  static setUserId(username:string){ localStorage.setItem("username",username);}

  static getUserId():string|null{return localStorage.getItem("username");}
  static getAccessToken(): string | null { return localStorage.getItem("access"); }

  static getRefreshToken(): string | null { return localStorage.getItem("refresh"); }

  static isAccessTokenValid(): boolean {
    let token = localStorage.getItem("access");
    if (token) {
      const helper = new JwtHelperService();
      const isExpired = helper.isTokenExpired(token);
      return !isExpired;
    }
    return false;
  }


  static isRefreshTokenValid(): boolean {
    let token = localStorage.getItem("refresh");
    if (token) {
      const helper = new JwtHelperService();

      // const decodedToken = helper.decodeToken(myRawToken);

      // // Other functions
      // const expirationDate = helper.getTokenExpirationDate(myRawToken);
      const isExpired = helper.isTokenExpired(token);
      return !isExpired;
    }
    return false;
  }


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    console.log("interceptor working");
    //adding header to the request
    const req = request.clone({
      setHeaders: {
        Authorization: `Bearer ${HeaderInterceptor.getAccessToken()}`
      }
    })

    if( request.headers.get("skipinterceptor")!=undefined)
    {
      return throwError(()=>{this.router.navigate(['/login'])});
    }

    //check if there is a 401 error
    return next.handle(req).pipe(catchError((err: HttpErrorResponse) => {
      console.log("interceptor refresh" + this.refresh);
      console.log("error status"+err.status);
      if(err.status==0)
      {
        return throwError(() => {
          this.router.navigate(['/dashboard/network-down']);
        });      }
      if (err.status === 401) {

        return this.http.post(environment.ip + '/app/refresh/', { 'refresh': HeaderInterceptor.getRefreshToken()}, {headers:{skipinterceptor:'true'}} )
          .pipe(catchError((err: HttpErrorResponse) => {
            return throwError(() => { this.router.navigate(['/login']); throw new Error("unable to refresh")})
          }))
          .pipe(
            switchMap((res: any) => {
              this.refresh = false;
              HeaderInterceptor.setAccessToken(res['access']);
              HeaderInterceptor.setRefreshToken(res['refresh']);
              return next.handle(request.clone({
                setHeaders: {
                  Authorization: `Bearer ${HeaderInterceptor.getAccessToken()}`
                }
              }));
            })
          );
      }
      return throwError(() => {
        this.router.navigate(['/login']);
      });
    }));
  }
}
