import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Rest } from '../model/restModel';
import { NavigationModel } from '../model/navigationModel';
import { getSubDomain } from '../services/dataStore.service';
import {  Slide, UICategory } from '../model/UiDataModel';
import { BillingDetailModel } from '../savings/savings.service';

export class RateModel{
    gold24:string="";
    gold24_name:string="";
    gold22:string="";
    gold22_name:string="";
    gold18:string="";
    gold18_name:string="";
    silver:string="";
    silver_999: string="";
    silver_name:string="";
    platinum:string="";
    platinum_name:string="";
    time:string="";
    id:number=0;
}


export interface PendingEmi{
  // used in mysavings.service.ts, home.service.ts
  // test both places if any change is made
  id:string;
  cost:number;
  code:string;
  installment_no:string;
  last_tried_on:string;
  payment_gateway_charge:number;
}

export interface EmiOrderResponse{
  //used in home.services.ts emi-order-list.services.ts
  gateway_order_id:string;
  gateway_id:string;
  gateway_type:number;
  order_id:string;
}


@Injectable({
    providedIn: 'root'
  })
export class HomeService {



  constructor(private http:HttpClient) {}


   getRate(){
    let fd=new FormData();
    fd.set("subdomain",getSubDomain());
    return this.http.post<RateModel>(`${environment.ip}/app/rate/`,fd);
   }


  getSlideList(){
    return this.http.get<Rest<Slide>>(`${environment.ip}/app/slide/?subdomain=${getSubDomain()}`)
  }

  getUiList(){
    return this.http.get<Rest<UICategory>>(`${environment.ip}/app/ui/?subdomain=${getSubDomain()}&code=HOME&ordering=sequence`);
  }

  getPendingEmis(){
    return this.http.get<PendingEmi[]>(`${environment.ip}/app/emis/pendingEmis/`);
  }

  createEmiOrder(subscription_id:string)
  {
    return this.http.post<EmiOrderResponse>(`${environment.ip}/app/emis/${subscription_id}/createEmiOrder/`,
    {cashfree_activated:true});
  }


  getBillingDetail(){
    return this.http.get<BillingDetailModel>(`${environment.ip}/app/billingInfo/getInfo/`)
  }

}
