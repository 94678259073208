import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface BookingData{
  weight:string;
  rate:string;
  payabale:string;
}

export interface BookingValidation{
  id:string;
  advance_percentage:string;
  redeem_duration:string;
  weight_from:string;
  weight_to:string;
}


export  class BookingDataPost{
    booking_id!:string;
    amount!:string;
    actual_cost!:string;
    booking_type!:string;
}

@Injectable({
  providedIn: 'root'
})




export class BookingserviceService {

  constructor(public http:HttpClient) { }
  createBooking(payable:Number,plan_id:any,booking_type:any,actual_cost:any)
  {
    return this.http.post(`${environment.ip}/app/booking/createBookingOrder/`
  ,{payable:payable,plan_id:plan_id,booking_type:booking_type,actual_cost:actual_cost});
  }
  completeBookingRazorpay(order_id:any){
    return this.http.post(`${environment.ip}/app/booking/completeBookingRazorpay/`
        ,{order_id:order_id});
  }

  allowedBooking()
  {
    return this.http.get<BookingValidation>(`${environment.ip}/app/booking/bookingAllowed/`)
  }

  isRateUpdated()
  {
    return this.http.get(`${environment.ip}/app/booking/isRateUpdated/`)
  }

  viewBooking(status:any)
  {

    // return this.http.get(`${environment.ip}/app/booking/viewBooking/`)
    return this.http.get(`${environment.ip}/app/booking/?status=${status}`)
  }

  getBookingByID(id:any)
  {
    return this.http.get(`${environment.ip}/app/booking/${id}/getBookingByID/`)

  }



}
