import { NgModule } from '@angular/core';
import { RouterModule, Routes ,PreloadAllModules} from '@angular/router';
// import { HomeComponent } from './home/home.component';
// import { MainNavComponent } from './main-nav/main-nav.component';
// import { SavingsComponent } from './savings/savings.component';
// import { SavingsCustDataComponent } from './savings/savings-cust-data/savings-cust-data.component';
// import {MysavingsComponent} from './mysavings/mysavings.component';
// import { TemplateComponent } from './template/template.component';
// import { EmiOrderListComponent } from './emi-order-list/emi-order-list.component';
// import { CategoryComponent } from './category/category.component';
// import {MyAccountComponent } from './my-account/my-account.component';
import {LoginComponent} from "./login/login.component";
import { HomeComponent } from './home/home.component';
import { SavingsComponent } from './savings/savings.component';
import { SavingsCustDataComponent } from './savings/savings-cust-data/savings-cust-data.component';
import {MysavingsComponent} from './mysavings/mysavings.component';
// import { TemplateComponent } from './template/template.component';
import { EmiOrderListComponent } from './emi-order-list/emi-order-list.component';
import { CategoryComponent } from './category/category.component';
import {MyAccountComponent } from './my-account/my-account.component';
import {NoLoginGuard} from './services/no-login-guard.service';
import { PrivacyComponent } from './privacy/privacy.component';
import { StatusPageComponent } from './status-page/status-page.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { PaymentPageComponent } from './payment-page/payment-page.component';
import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [
  { path: 'dashboard',
   loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
},
  {path:"spg", loadChildren:()=>import('./simple-page/simple-page.module').then(m=>m.SimplePageModule)},
  { path: '',
   loadChildren: () => import('./landing-home/landing-home.module').then(m => m.LandingHomeModule),
   canActivate:[NoLoginGuard] },
   { path: 'landing-page',
   loadChildren: () => import('./landing-home/landing-home.module').then(m => m.LandingHomeModule),
   canActivate:[NoLoginGuard] },
  {path:"login",component:LoginComponent},
  {path:"logout",component:LogoutComponent},
  {path:"privacy",component:PrivacyComponent},
  {path:"about-us",component:AboutUsComponent},
  // {path:"pg-initiate",component:PaymentPageComponent},// THIS COMPONENT TAKES IN ORDER_ID AND CF_SESSION_ID AND STARTS THE PAYMENT TRANSACTION WITH CASHFREE
  {path:"returns-&-refund-policy",component:RefundPolicyComponent},
  {path:"cf-pay",component:StatusPageComponent},
  // {path:"template",component:TemplateComponent},
  { path: 'page', loadChildren: () => import('./normal-nav/normal-nav.module').then(m => m.NormalNavModule) },
  // {path:"status",component:StatusPageComponent}, deprecated

  // {path:"category",component:CategoryComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{preloadingStrategy:PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
