import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import {LoginService,TokenModel} from '../login/login.service';
import {HeaderInterceptor} from '../services/header.interceptor';

@Injectable()
export class NoLoginGuard implements CanActivate {

    constructor( public router: Router,
        private service: LoginService) { }

    canActivate(): boolean {
        if(HeaderInterceptor.isAccessTokenValid())
        {this.router.navigate(['/dashboard/home/']); return true;}

        if(!HeaderInterceptor.isAccessTokenValid() && HeaderInterceptor.isRefreshTokenValid())
        {this.router.navigate(['/dashboard/home/']);return true;}

        return true;
    }


    }
