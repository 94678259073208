import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AnimationItem } from 'lottie-web';
import { DialogData } from 'src/app/model/dialogDataModel';

// Demo for lottie taken from https://dev.to/riapacheco/adding-animated-illustrations-to-an-angular-app-with-lottie-ngx-lottie-4j0o
// github for lottie rendering from https://github.com/riapacheco/lottie-demo

@Component({
    selector: 'dialogComponent',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.css'],

  })
  export class DialogComponent {

    constructor(
      public dialogRef: MatDialogRef<DialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
      console.log("dialog component called");
        (data);
    }

    onAnimate(animationItem: AnimationItem): void {
      console.log(animationItem);
    }

    btnClick(): void {
      this.dialogRef.close();
      this.data.buttonFunction();
    }
  }
