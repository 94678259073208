<h2 mat-dialog-title style="text-align:center;">{{data.title}}</h2>
<div mat-dialog-content style="text-align:center;justify-content:center;">
  <div class="lottie-animation" style="height:100px">
    <ng-lottie
      [options]="data.animationOptions"
      >
    </ng-lottie>
  </div>
  <div class="regular-grey">{{data.description}}</div>
  <div style="display:flex;justify-content:center;" *ngIf="data.show_button">
    <button class="custom-dialog-btn gold-bg semibold-white w-60 p-4"  (click)="btnClick()">Go to homepage</button>
    <!-- <button mat-button  cdkFocusInitial>Ok</button> -->
  </div>

</div>
