import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DashboardService, ShopModel } from '../dashboard/dashboard.service';
import { getStoreName } from '../services/dataStore.service';
import { injectClarity } from '../services/scriptInjection.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {
  COMPANY_NAME=getStoreName();
  shopModel = new ShopModel();
  constructor(private location:Location,
    private service: DashboardService) { }

  ngOnInit(): void {
    this.service.getShop().subscribe((resp:ShopModel)=>{
      this.shopModel = resp;

      this.shopModel.setName(resp.name);
      this.shopModel.setIconData(resp.logo);
      this.shopModel.setClarityId(resp.clarity_id);
      injectClarity(resp.clarity_id);
      this.COMPANY_NAME=getStoreName();
  },
            (err)=>{});

  }


  back(){
    this.location.back();
  }
}
