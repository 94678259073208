import {
  Component,
  OnInit,
  NgZone,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { WindowRefService } from '../window-ref.service';
import {
  BookingData,
  BookingValidation,
  BookingserviceService,
} from './bookingservice.service';
import { RazorPay } from '../services/razorpayService';
import { CashFree } from '../services/cashfee.service';
import { HomeService, RateModel } from '../home/home.service';
import { BillingDetailModel, SavingsService } from '../savings/savings.service';
import { DialogComponent } from '../template/dialog/dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticService } from '../services/analytics.service';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css'],
  providers: [BookingserviceService, HomeService,SavingsService],
})
export class BookingsComponent implements OnInit {
  @ViewChild('continue', { static: true }) myTemplate:
    | TemplateRef<any>
    | undefined;
  activeButton: string = 'amount';
  rate!: any;

  booking_data!: BookingData;
  booking_validation!: BookingValidation[];
  advance_percentage!: any;
  month!: any;
  booking_id: any;
  payable_or_gram!: any;
  amount_or_weight!: any;
  weight!: any;
  advance_amount!: any;
  order_id!: any;
  has_billing_detail = false;
  is_today_date: boolean = false;

  amount!: Number;
  processing_dialog_ref?: MatDialogRef<DialogComponent> | null;
  billing_detail?:BillingDetailModel|null;
  actual_cost: any;
  constructor(
    private service: BookingserviceService,
    private winRef: WindowRefService,
    private homeservice: HomeService,
    private zone: NgZone,
    public analytics: AnalyticService,
    public dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private modalService: NgbModal,
    private billingservice:SavingsService,

  ) {}

  ngOnInit(): void {
    this.fetchRate();
    this.fetchBillingDetail()
    this.toggleButton('weight');
    this.service.allowedBooking().subscribe((data: any) => {
      this.booking_validation = data;
    });

  }

  openProcessingDialog() {
    this.zone.run(() => {
      this.processing_dialog_ref = this.dialog.open(DialogComponent, {
        width: '90vw',
        panelClass: 'custom-dialog',
        disableClose: true,
        data: {
          title: 'Processing!',
          description: '',
          animationOptions: { path: '/assets/lottie/processing_round.json' },
          show_button: false,
        },
      });
    });
  }
  error() {

    if (this.is_today_date == false) {
      this.snackBar.open("Can't Proceed as Rate is not Updated !",  undefined,
      { duration: 1500 });
      return false;
    }

    if (this.activeButton == 'amount') {
      if (isNaN(Number(this.amount_or_weight))) {
        this.snackBar.open('Input a valid cost');
        return false;
      } else if (
        this.amount_or_weight <
        Number(this.booking_validation[0].weight_from) *
          Number(this.rate.gold22)
      ) {
        this.snackBar.open(
          `Minimum Booking should be ₹${
            Number(this.booking_validation[0].weight_from) *
            Number(this.rate.gold22)
          }`,
          undefined,
          { duration: 1500 }
        );
        return false;
      } else if (
        this.amount_or_weight >
        Number(
          this.booking_validation[this.booking_validation.length - 1].weight_to
        ) *
          Number(this.rate.gold22)
      ) {
        this.snackBar.open(
          `Max Booking cost is ₹${
            Number(
              this.booking_validation[this.booking_validation.length - 1]
                .weight_to
            ) * Number(this.rate.gold22)
          }`,
          undefined,
          { duration: 1500 }
        );
        return false;
      }
      return true;
    } else if (this.activeButton == 'weight') {
      if (isNaN(Number(this.amount_or_weight))) {
        this.snackBar.open('Input a valid weight',
        undefined,
        { duration: 1500 });
        return false;
      } else if (
        this.amount_or_weight < Number(this.booking_validation[0].weight_from)
      ) {
        this.snackBar.open(
          `Minimum Booking should be ₹${Number(
            this.booking_validation[0].weight_from
          )}gm`,
          undefined,
          { duration: 1500 }
        );
        return false;
      } else if (
        this.amount_or_weight >
        Number(
          this.booking_validation[this.booking_validation.length - 1].weight_to
        )
      ) {
        this.snackBar.open(
          `Max Booking Weight is ${Number(
            this.booking_validation[this.booking_validation.length - 1]
              .weight_to
          )}gm`,
          undefined,
          { duration: 1500 }
        );
        return false;
      }
      return true;
    }
    return true;
  }

  bookingValidation(value: any) {

    if (this.activeButton == 'amount') {
      var actual_gram = (
        this.amount_or_weight / Number(this.rate.gold22)
      ).toFixed(3);
      for (let i = 0; i < this.booking_validation.length; i++) {
        if (
          this.amount_or_weight / Number(this.rate.gold22) >=
            Number(this.booking_validation[i].weight_from) &&
          this.amount_or_weight / Number(this.rate.gold22) <=
            Number(this.booking_validation[i].weight_to)
        ) {
          this.advance_percentage =
            this.booking_validation[i].advance_percentage;
          this.month = this.booking_validation[i].redeem_duration;
          this.booking_id = this.booking_validation[i].id;

          this.amount = Number(value.target.value);
          this.payable_or_gram = value.target.value
            ? value.target.value /
              (this.advance_percentage / 100) /
              Number(this.rate.gold22)
            : '';
          this.payable_or_gram = this.payable_or_gram.toFixed(3);
        } else if (Number(actual_gram) == 0) {
          this.payable_or_gram = 0;
          this.advance_percentage = 0;
          this.month = 0;
        }
      }
    }
    var actual_cost = (value.target.value * Number(this.rate.gold22)).toFixed(
      3
    );
    if (this.activeButton == 'weight') {
      for (let i = 0; i < this.booking_validation.length; i++) {
        if (
          this.amount_or_weight >=
            Number(this.booking_validation[i].weight_from) &&
          this.amount_or_weight <= Number(this.booking_validation[i].weight_to)
        ) {
          this.advance_percentage =
            this.booking_validation[i].advance_percentage;
          this.month = this.booking_validation[i].redeem_duration;
          this.booking_id = this.booking_validation[i].id;
          this.payable_or_gram = value.target.value
            ? (this.advance_percentage / 100) *
              value.target.value *
              Number(this.rate.gold22)
            : '';
          this.actual_cost = this.payable_or_gram;
          this.payable_or_gram = this.roundOffTo500(
            this.payable_or_gram
          ).toFixed(3);
          this.amount = Number(this.payable_or_gram);
        } else if (Number(actual_cost) == 0) {
          this.payable_or_gram = 0;
          this.advance_percentage = 0;
          this.month = 0;
        }
      }
    }
  }
  fetchRate() {
    // this.service.isRateUpdated().subscribe(
    //   (data: any) => {
    //     console.log(data);
    //     this.rate = data;
    //     this.is_today_date = data.is_today;
    //     if (this.is_today_date == false) {
    //       this.modalService.open(this.myTemplate, { centered: true });
    //     }
    //   },
    //   (error: any) => {}
    // );
    this.homeservice.getRate().subscribe
    (
      (data:RateModel)=>
      {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const dateObject = new Date(Date.parse(data.time));
        dateObject.setHours(0,0,0,0)
        if(today.getTime()===dateObject.getTime())
        {
          this.rate = data;
          this.is_today_date=true
        }
        else{
          this.is_today_date=false
          this.modalService.open(this.myTemplate, { centered: true });
        }
      },
      (error:any)=>
      {
        console.log("Error while finding rates")
      }
    )

  }
  roundOffTo500(number: number): number {
    if (number < 100) {
      // Handle numbers less than 100
      if (number >= 50) {
        return 100; // Round up to 100
      } else {
        return number; // Round down to 0
      }
    } else if (number >= 100 && number < 10000) {
      const quotient = Math.floor(number / 500); // Divide by 500 and round down
      const remainder = number % 500; // Calculate the remainder

      if (remainder >= 250) {
        return (quotient + 1) * 500; // Round up to the next 500
      } else {
        return quotient * 500; // Round down to the current 500
      }
    } else {
      const quotient = Math.floor(number / 1000); // Divide by 1000 and round down
      const remainder = number % 1000; // Calculate the remainder

      if (remainder >= 500) {
        return (quotient + 1) * 1000; // Round up to the next 1000
      } else {
        return quotient * 1000; // Round down to the current 1000
      }
    }
  }
  fetchBillingDetail(){
    this.billingservice.getBillingDetail().subscribe(
      (resp: BillingDetailModel) => {
        this.billing_detail=resp;
        if (resp.pincode.trim() != "" || resp.pincode != null) {
          this.has_billing_detail = true;
        }
        else {
          console.error("does not have billing details")
        }

      },
      (err: any) => {
        this.has_billing_detail = false;
      }
    )

  }

  createBooking() {

    if (Number(this.amount) > 100000) {
      this.snackBar.open(
        'Note: You can make multiple bookings but each booking should not exceed 1 Lakh'
        ,undefined,
          { duration: 1500 });
      return;
    }

    // if billing data exists
    if (this.has_billing_detail)
    {
      this.openProcessingDialog();
      this.service
      .createBooking(
        Number(this.amount),
        this.booking_id,
        this.activeButton,
        this.actual_cost
      )
      .subscribe(
        (data: any) => {
          if (data.gateway_type == 0) {
            this.order_id = data.gateway_id;
            new RazorPay(data.gateway_id, this.winRef).pay(
              String(Number(100) * 100),
              () => {
                this.service
                  .completeBookingRazorpay(this.order_id)
                  .subscribe((data: any) => {
                  });
                this.openSuccessDialog();
              },
              () => {
                this.openFailDialog();
              },
              this.billing_detail!.email,
              this.billing_detail!.phone
            );
          } else {
            new CashFree(this.winRef).pay(
              data.gateway_id,
              data.gateway_order_id
            );
          }
        },
        (err) => {
          console.log('error occured');
        }
     );
    }
    else{
      console.log("does not have billing details")
      this.router.navigate(['/page/savings-plan-cust-data',
        {
          booking_id: this.booking_id,
          amount: this.amount,
          actual_cost:this.actual_cost,
          booking_type:this.activeButton,
        }]);
    }

  }
  openSuccessDialog() {
    this.closeProcessingDialog();
    this.analytics.logEvent("booking-success");

    this.zone.run(() => {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '90vw',
        panelClass: 'custom-dialog',
        disableClose: true,
        data: {
          title: 'Success',
          description: 'Booking created successfully!',
          animationOptions: { path: '/assets/lottie/success.json' },
          button_name: 'Go to Booking Detail Page!',
          show_button: true,
          buttonFunction: () => {
            dialogRef.close();
            this.router.navigate([
              'dashboard/home',
              { order_id: this.order_id },
            ]);
          },
        },
      });
    });
  }
  openFailDialog() {
    this.closeProcessingDialog();
    // this.analytics.logEvent("emi-fail");
    this.zone.run(() => {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '90vw',
        panelClass: 'custom-dialog',
        disableClose: true,
        data: {
          title: 'Fail!',
          description: 'Oops! Payment not complete.',
          animationOptions: { path: '/assets/lottie/fail.json' },
          button_name: 'Go somewhere else',
          show_button: true,
          buttonFunction: () => {
            dialogRef.close();
            // this.loadPendingEmis();
            // this.router.navigate(["/dashboard/home/"]);
          },
        },
      });
    });
  }
  closeProcessingDialog() {
    this.processing_dialog_ref?.close();
  }

  toggleButton(type: any) {
    var amount = <HTMLDivElement>document.getElementById('amount_block');
    var weight = <HTMLDivElement>document.getElementById('grams_block');
    this.activeButton = type;
    if (type == 'amount') {
      weight.style.display = 'block';
      amount.style.display = 'none';
      this.amount_or_weight = '';
      this.payable_or_gram = '';
    }
    if (type == 'weight') {
      weight.style.display = 'none';
      amount.style.display = 'block';
      this.amount_or_weight = '';
      this.payable_or_gram = '';
    }
  }
}
