import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService, TokenModel } from './login.service';
import { HeaderInterceptor } from '../services/header.interceptor';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnalyticService } from '../services/analytics.service';
import { environment } from 'src/environments/environment';
import { MetapixelService } from '../services/metapixel.service';
import { catchError, of } from 'rxjs';



interface ErrorModel {
  detail: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  VERSION_NO = environment.version;
  show_otp_box = false;
  show_login_button = false;
  show_otp_spinner = false;
  show_login_spinner = false;
  show_otp_button = true;
  show_resend_otp = false;
  show_retry_call = false;
  show_otp_send_text = false
  ui_phone_input_disabled = false;
  otp_counter = 60;
  last_resend_time: number = 0;
  ui_show_phone_edit = false;

  mobile_number: string = "";
  otp_value: string = "";
  @ViewChild('ngOtpInput') ngOtpInputRef: any;

  send_otp_button: any;
  count_down_p:any;
  show_timer:any;
  resend_count=0; // maximum 2 resends beyond which customer should be asked to try after more time and post a error request
  constructor(private loginService:LoginService,
             private router:Router,
             private analytics: AnalyticService,
             private snackBar:MatSnackBar,
             private metaPixel:MetapixelService) {
              this.analytics.setAppVersion();
             }

  ngOnInit(): void {
    this.metaPixel.trackPageView("login_screen");
    this.getElementsById();
    let token = HeaderInterceptor.getAccessToken()
    if (token == null) { }
    else {
      this.loginService.isLoggedIn(token).subscribe(
        (resp: any) => {
          this.router.navigate(['/dashboard']);
        },
        (err: any) => { }
      );
    }

  }

  getElementsById() {
    this.send_otp_button = <HTMLInputElement>(document.getElementById("send_otp_btn"));
    this.count_down_p = (<HTMLInputElement>(document.getElementById('countdown')));
    this.show_timer = (<HTMLInputElement>(document.getElementById('send_otp_label')));
  }


  onOtpChange(value: any) {
    if (value.length == 4) {
      this.show_login_button = true;
      this.otp_value = value;
    }
    else {
      this.show_login_button = false;
      this.otp_value = value;
    }

  }
  onPhoneChange(event: any) {
    this.mobile_number = event.target.value;
  }

  sendOtp() {
    this.ui_phone_input_disabled = true;
    this.ui_show_phone_edit = true;
    if (this.show_otp_spinner) { return; }

    if (this.mobile_number.length != 10) { this.snackBar.open("Invalid Phone Number!", "", { duration: 3000 }); return; }
    this.analytics.logEvent("send_otp");
    this.show_otp_spinner = true;
    let fd = new FormData();
    fd.append("mobile", this.mobile_number);
    this.loginService.sendOtp(fd).subscribe((data) => {
      this.show_otp_spinner = false;
      this.show_otp_box = true;
      this.show_otp_button = false;
      this.show_login_button = true;
      this.show_otp_send_text = true;
      let seconds: any = 30;
      this.otp_counter = seconds;
      let countdown = setInterval(() => {
        seconds--;
        this.otp_counter = seconds;
        if (seconds <= 0) {
          clearInterval(countdown);
          this.show_resend_otp = true;
        }
      }, 1000);
    },
      (err) => {
        console.log(err);
        this.snackBar.open("Oops! , Server Error. Try again After some time.", "", { duration: 3000 });
        this.show_otp_spinner = false;
      })
  }

  resendOtp() {
    this.resend_count++;
    if (this.resend_count > 2 && (Date.now() - this.last_resend_time) / (60 * 60 * 60) > 30) { this.otp_counter = 0; this.last_resend_time = 0; }//reset otp counter and timer
    if (this.resend_count > 2) { this.snackBar.open("Please Retry after 30 mins", "", { duration: 3000 }); return; }
    this.show_resend_otp = false;
    // this.show_otp_spinner=true;
    let fd = new FormData();
    fd.append("mobile", this.mobile_number);
    this.loginService.resendOtp(fd).subscribe((data) => {
      this.analytics.logEvent("resend_otp")
      console.log(data);
      this.last_resend_time = Date.now();
      this.show_resend_otp = false;
      this.show_otp_spinner = false;
      this.show_otp_box = true;
      this.show_otp_button = false;
      let seconds: any = 30;
      this.otp_counter = seconds;
      let countdown = setInterval(() => {
        seconds--;
        this.otp_counter = seconds;
        if (seconds <= 0) {
          this.show_resend_otp = false;
          this.show_retry_call = true;
          clearInterval(countdown);

        }
      }, 1000);
    })
  }

  retryCall() {
    this.resend_count++;
    if (this.resend_count > 2 && (Date.now() - this.last_resend_time) / (60 * 60 * 60) > 30) { this.otp_counter = 0; this.last_resend_time = 0; }//reset otp counter and timer
    if (this.resend_count > 2) { this.snackBar.open("Please Retry after 30 mins", "", { duration: 3000 }); return; }
    this.show_resend_otp = false;
    // this.show_otp_spinner=true;
    let fd = new FormData();
    fd.append("mobile", this.mobile_number);
    fd.append("is_voice", "1");
    this.loginService.resendOtp(fd).subscribe((data) => {
      this.analytics.logEvent("resend_otp")
      console.log(data);
      this.last_resend_time = Date.now();
      this.show_resend_otp = false;
      this.show_otp_spinner = false;
      this.show_otp_box = true;
      this.show_otp_button = false;
      let seconds: any = 30;
      this.otp_counter = seconds;
      let countdown = setInterval(() => {
        seconds--;
        this.otp_counter = seconds;
        if (seconds <= 0) {
          this.show_retry_call = true;
          clearInterval(countdown);

        }
      }, 1000);
    })
  }

  login() {
    if (this.show_login_spinner) { return; }
    if (this.otp_value.length != 4) { this.snackBar.open("Please Enter Otp!", "", { duration: 3000 }); return; }
    this.show_login_spinner = true;
    this.loginService.login(this.mobile_number, this.otp_value).subscribe((data) => {
      this.analytics.logEvent("login");
      this.analytics.setUserId(this.mobile_number);
      HeaderInterceptor.setUserId(this.mobile_number);
      HeaderInterceptor.setAccessToken(data.access);
      HeaderInterceptor.setRefreshToken(data.refresh);
      this.show_login_spinner = false;

      // this.loginService.getDGStatus().pipe(catchError(err => of(err))).subscribe((digiRes:any) => {

      //   if (digiRes?.status == 200) {
      //     localStorage.setItem("digiStatus", "REGISTERED")
      //   } else if (digiRes?.status == 404) {
      //     localStorage.setItem("digiStatus", "NOT_REGISTERED")
      //   } else if (digiRes?.status == 401) {
      //     localStorage.setItem("digiStatus", "NOT_ELIGIBLE")
      //   }

      //   this.router.navigate(['/dashboard/home/']);
      //   console.log("navigating to dashboard");
      // })
      this.show_login_spinner=false;
      this.router.navigate(['/dashboard/home/']);
      console.log("navigating to dashboard");
      this.metaPixel.trackCustomEvent("user_logged_in",{})
      console.log(data);
    },
      (err) => {
        this.show_login_spinner = false;
        this.snackBar.open(err.error.detail, "", { duration: 3000 });
        this.show_login_spinner = false;
        console.log(err);
      })


  }

  back() {
    this.router.navigate(['/']);
  }

  editPhone() {
    this.show_otp_spinner = false;
    this.show_otp_box = false;
    this.show_otp_button = true;
    this.show_login_button = false;
    this.show_otp_send_text = false;
    this.ui_phone_input_disabled = false;
    this.ui_show_phone_edit = false;
    this.ngOtpInputRef.setValue("");

  }

}
