import { Component, OnInit } from '@angular/core';
import { HeaderInterceptor } from '../services/header.interceptor';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    localStorage.clear();
    HeaderInterceptor.setAccessToken(null);
    HeaderInterceptor.setRefreshToken(null);
    localStorage.clear()
  }

}
